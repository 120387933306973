import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateSelect from '../shared/DateSelect';
import LocationSelect from '../shared/LocationSelect';
import { useParams } from 'react-router-dom';
import { getTherapistAvailability } from '../../../../helper/api.helper';
import toast from 'react-hot-toast';
import SessionSelect from '../shared/SessionSelect';
import TimeslotSelect from '../shared/TimeslotSelect';

const ConsultationDetails = ({
  selectedEventType,
  handleEventTypeChange,
  selectedLocation,
  handleLocationChange,
  therapist,
  selectedDate,
  handleDateClick,
  selectedTimeIndex,
  handleTimeClick,
  handleBook,
  formatDate,
  services,
  locations,
  setLoading,
  selectedTime
}) => {
  const { id } = useParams();

  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    if (id && selectedDate && selectedEventType && selectedLocation) {
      setLoading(true);
      getTherapistAvailability(id, {
        serviceId: selectedEventType,
        locationId: selectedLocation,
        selectedDate: selectedDate.getTime(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setTimeSlots(res.data.slots);
            setLoading(false);
          } else {
            setLoading(false);

            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);

          toast.error(err.message);
        });
    }
  }, [id, selectedDate, selectedEventType, selectedLocation]);
  return (
    <>
      <div>
        <SessionSelect
          selectedEventType={selectedEventType}
          handleEventTypeChange={handleEventTypeChange}
          services={services}
        />

        <LocationSelect
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          therapist={therapist}
          locations={locations}
        />

        <DateSelect selectedDate={selectedDate} handleDateClick={handleDateClick} />
      </div>
      <div>
        <TimeslotSelect
          timeSlots={timeSlots}
          selectedTimeIndex={selectedTimeIndex}
          handleTimeClick={handleTimeClick}
        />
      </div>
      <div className="margin-top--md" style={{ textAlign: 'start' }}>
        <Button
          disabled={!selectedDate || !selectedEventType || !selectedLocation || !selectedTime}
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          onClick={handleBook}
          sx={{ textTransform: 'none', maxWidth: '350px' }}>
          Book session for {formatDate(selectedDate)}
        </Button>
      </div>
      <style jsx>{`
        p,
        h3,
        h4,
        .main-div > span,
        div {
          color: black;
        }
      `}</style>
    </>
  );
};

export default ConsultationDetails;
