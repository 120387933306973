import { createStore, action } from 'easy-peasy';

const store = createStore({
  user: false,
  logo: null,
  banner: null,

  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setLogo: action((state, payload) => {
    state.logo = payload;
  }),
  setBanner: action((state, payload) => {
    state.banner = payload;
  })
});

export default store;
