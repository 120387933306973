import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
// import { locations, therapists } from '../../../helper/data.helper';
import PersonalDetails from './personal-details/PersonalDetails';
import MobileViewPopup from './mobile-view-popup/MobileViewPopup';
import ConsultationDetails from './consultation-details/ConsultationDetails';
import AddressDetails from './address-details/AddressDetails';
import ConsultationModes from './ConsultationModes';
import ImageDetails from './ImageDetails';
import toast from 'react-hot-toast';
import { confirmBooking, getTherapistDetails } from '../../../helper/api.helper';
import Loader from '../../../components/loader/Loader';
import { getOrgCode } from '../../../helper/utils.helper';

const TherapistDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(services?.[0]?.id || '');
  const [selectedLocation, setSelectedLocation] = useState(locations?.[0]?.id || '');
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(-1);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [therapist, setTherapist] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleTimeClick = (timeSlot, index) => {
    setSelectedTime(timeSlot);
    setSelectedTimeIndex(index);
  };

  const handleBook = async () => {
    const orgCode = getOrgCode();
    if (!selectedEventType) {
      toast.error('Please select a service');
      return;
    }
    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }
    if (!selectedDate) {
      toast.error('Please select a date');
      return;
    }
    if (!selectedTime) {
      toast.error('Please select a timeslot');
      return;
    }
    setLoading(true);
    await confirmBooking(orgCode, id, {
      serviceId: selectedEventType,
      locationId: selectedLocation,
      selectedDate: selectedDate.getTime(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selectedTimeSlot: selectedTime
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          toast.success('Session booked successfully');
          setIsDrawerOpen(false);
          setSelectedDate(new Date());
          setSelectedEventType(services?.[0]?.id || '');
          setSelectedLocation(locations?.[0]?.id || '');
          setSelectedTime('');
          setSelectedTimeIndex();
          setLoading(false);
        } else {
          setLoading(false);
          setIsDrawerOpen(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsDrawerOpen(false);
        toast.error(err.message);
      });
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const formatDate = (date) => {
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const isMobile = useMediaQuery('(max-width:770px)');

  const handleDateClick = (date) => {
    setSelectedTime(null);
    setSelectedTimeIndex();
    setSelectedDate(new Date(date));
  };

  useEffect(() => {
    setLoading(true);
    getTherapistDetails(id)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setTherapist(res.data.profile);
          setLocations(res.data.locations);
          setServices(res.data.services);
          if (res?.data?.services && res.data.services.length > 0) {
            setSelectedEventType(res.data.services[0].id);
          }
          if (res?.data?.locations && res.data.locations.length > 0) {
            setSelectedLocation(res.data.locations[0].id);
          }
          setLoading(false);
        } else {
          setLoading(false);

          toast.error(res.message);
          navigate('/homepage/therapist-list');
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err.message);
      });
  }, [id]);

  return (
    <>
      {isLoading ? <Loader /> : null}
      {therapist ? (
        <>
          <div className="top-div"></div>
          <div className="main-div flex-container">
            <div className="left-div">
              <div className="bottom-div flex-container">
                <div className="inner-1st-div flex-container">
                  <ImageDetails therapist={therapist} />
                </div>
                <div className="inner-2nd-div margin-top--md">
                  <PersonalDetails therapist={therapist} />
                </div>

                <div
                  className="inner-3rd-div margin-top--sm"
                  // style={{ paddingTop: "20px" }}
                >
                  {locations && locations.length > 0 && (
                    <h4
                      style={{
                        borderBottom: '1px solid var(--border-gray)',
                        paddingBottom: '10px'
                        // marginBottom: '15px'
                      }}>
                      Locations
                    </h4>
                  )}
                  {locations?.some((location) => location.meetingType === 0) && (
                    <div className="margin-top--md">
                      <ConsultationModes locations={locations} />
                    </div>
                  )}
                </div>

                {locations?.some((location) => location.meetingType === 1) && (
                  <div className="location-div inner-3rd-div margin-top--xxxs">
                    <AddressDetails locations={locations} />
                  </div>
                )}
              </div>
            </div>
            {window.innerWidth > 770 &&
              services &&
              services.length > 0 &&
              locations &&
              locations.length > 0 && (
                <div className="right-div">
                  <ConsultationDetails
                    selectedEventType={selectedEventType}
                    handleEventTypeChange={handleEventTypeChange}
                    selectedLocation={selectedLocation}
                    handleLocationChange={handleLocationChange}
                    therapist={therapist}
                    selectedDate={selectedDate}
                    handleDateClick={handleDateClick}
                    selectedTimeIndex={selectedTimeIndex}
                    handleTimeClick={handleTimeClick}
                    handleBook={handleBook}
                    formatDate={formatDate}
                    services={services}
                    locations={locations}
                    setLoading={setLoading}
                    selectedTime={selectedTime}
                  />
                </div>
              )}
          </div>

          {isMobile && (
            <AppBar
              position="fixed"
              sx={{
                backgroundColor: '#fff',
                top: 'auto',
                bottom: 0,
                padding: '0px 10px 0px 0px'
              }}>
              <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* <small>Next Available: {formatDate(new Date())} </small> */}

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  onClick={toggleDrawer(true)}
                  sx={{ textTransform: 'none' }}>
                  Check availability
                </Button>
              </Toolbar>
            </AppBar>
          )}

          <MobileViewPopup
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            selectedEventType={selectedEventType}
            handleEventTypeChange={handleEventTypeChange}
            selectedLocation={selectedLocation}
            handleLocationChange={handleLocationChange}
            therapist={therapist}
            selectedDate={selectedDate}
            handleDateClick={handleDateClick}
            selectedTimeIndex={selectedTimeIndex}
            handleTimeClick={handleTimeClick}
            formatDate={formatDate}
            services={services}
            locations={locations}
            handleBook={handleBook}
            selectedTime={selectedTime}
          />
        </>
      ) : (
        <div style={{ height: '100vh' }}></div>
      )}

      <style jsx>
        {`
          p,
          h3,
          h4,
          .main-div > span,
          div {
            color: black;
          }
          .right-div > span {
            color: unset;
          }

          .top-div {
            height: 100px;
            width: 100%;
            background-color: #e5efff;
            // margin-top: 50px;
          }
          .main-div {
            min-height: 85vh;
          }
          .left-div {
            width: 65%;
          }
          .bottom-div {
            width: 100%;
            padding: 10px 10px 10px 20px;

            flex-direction: column;
            justify-content: center;
            align-items: start;
          }
          .inner-1st-div {
            width: 87%;
            justify-content: start;
            align-items: center;
            padding-left: 10px;
            margin-top: -20px;
          }

          .inner-2nd-div {
            width: 85%;
            padding: 0px 10px;
          }
          .inner-3rd-div {
            width: 85%;
            margin-left: 10px;
          }

          .location-div {
            justify-content: start;
            padding-bottom: 60px;
          }
          .right-div {
            width: 33%;
            margin-top: 20px;
            padding-bottom: 50px;
            padding-left: 10px;
          }
          @media only screen and (min-width: 1300px) {
            .bottom-div {
              margin-left: 10%;
            }
          }

          @media only screen and (max-width: 1025px) {
            .inner-1st-div {
              width: 100%;
            }
            .inner-3rd-div {
              width: 100%;
            }
          }
          @media only screen and (max-width: 815px) {
            .main-div {
              flex-direction: column;
            }
            .right-div {
              margin-left: 1%;
            }
            .left-div {
              width: 90%;
            }
          }

          @media only screen and (max-width: 600px) {
            .left-div {
              width: 100%;
            }
            .bottom-div {
              padding: 5px;
            }
            .right-div {
              width: 100%;
            }
            .inner-3rd-div {
              margin-left: 10px;
            }
          }

          @media only screen and (max-width: 350px) {
            .right-div {
              padding-left: 4px;
            }
          }
        `}
      </style>
    </>
  );
};

export default TherapistDetailPage;
